import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import ReactJsonViewCompare from "react-json-view-compare";


const OperationLogs = (props) => {
  // const [events, setEvents, textSelectd ,setTextSelected] = useState([]);

  const [allValues, setAllValues] = useState({
    events: [],
    oldTextToCompare: null,
    newTextToCompare: null,
    searchParams: {
      from_date: "2023-01-18 00:00:00",
      to_date: "2023-01-19 23:59:59",
    },
    show: false,
    jwt: "",
  });

  const handleClose = () => {
    setAllValues({
      ...allValues,
      oldTextToCompare: null,
      newTextToCompare: null,
      show: false,
    });
  };
  const handleShow = () => setAllValues({ ...allValues, show: true });

  useEffect(() => {
    // console.log({ allValues });

    if (allValues.jwt === "") {
      return;
    }
    const queryString = getQueryString(allValues.searchParams);
   // console.log({queryString})
    fetch(
      "https://dashboard.situm.com/api/v1/reports/ops_audit.json?" + queryString,
      {
        method: "GET",
        headers: new Headers({
          Authorization:
            "Bearer "+ allValues.jwt,
        }),
      }
    )
      .then((response) => {
        if(response.status > 400){
          alert("ERROR: Invalid Token. HTTP code: " + response.status + ", HTTP status: " + response.statusText )
        }
        return response.json() 
      })
      .then((eventData) => {
        const userIds = eventData.data
          .map((eventin) => [
            eventin.user_id,
            eventin.performed_by_impersonated_user_id,
          ])
          .reduce((a, b) => a.concat(b), [])
          .filter(
            (userId) => userId !== "00000000-0000-0000-0000-000000000000"
          );
        const userIdsRetrieve = [...new Set(userIds)];
        //  console.log(userIdsRetrieve)

        if (userIdsRetrieve.length < 1) {
          setAllValues({ ...allValues, events: eventData.data });
          return;
        }

        fetch(
          "https://dashboard.situm.com/api/v1/users?size=10000&ids=" +
            userIdsRetrieve.toString(),
          {
            method: "GET",
            headers: new Headers({
              Authorization:
              "Bearer "+ allValues.jwt,
            }),
          }
        )
          .then((response) => response.json())
          .then((userData) => {
            // console.log(eventData)
            // console.log(userData)
            const userInfo = userData.data
              .map((user) => ({ id: user.id, email: user.email }))
              .reduce(function (map, obj) {
                map[obj.id] = obj.email;
                return map;
              }, {});

            const eventsComplete = eventData.data.map((eventinfo) => ({
              timestamp: eventinfo.timestamp,
              action: eventinfo.action,
              entity_type: eventinfo.entity_type,
              entity_id: eventinfo.entity_id,
              organization:
                eventinfo.organization_name + " --- " + eventinfo.organization_id,
              performed_by_user:
                userInfo[eventinfo.user_id] === undefined
                  ? "-----"
                  : userInfo[eventinfo.user_id] +
                    " --- " +
                    eventinfo.user_id,
              performed_by_impersonated_user:
                userInfo[eventinfo.performed_by_impersonated_user_id] ===
                undefined
                  ? "-----"
                  : userInfo[eventinfo.performed_by_impersonated_user_id],
              notification_value: eventinfo.notification_value,
            }));

            // console.log(eventsComplete)
            setAllValues({ ...allValues, events: eventsComplete });
            //   setEvents(eventsComplete);
          });
      })
      .catch((err) => {
        //alert(err)
        console.log(err);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues.searchParams, allValues.jwt]);

  const getQueryString = (queries) => {
    return Object.keys(queries)
      .reduce((result, key) => {
        return [
          ...result,
          `${encodeURIComponent(key)}=${encodeURIComponent(queries[key].trim())}`,
        ];
      }, [])
      .join("&");
  };

  const handleCellClick = (e) => {
    // console.log("entra")
    // console.log(e.target.textContent)

    if (allValues.oldTextToCompare == null) {
      setAllValues({ ...allValues, oldTextToCompare: JSON.parse(e.target.textContent) });
    } else if (allValues.newTextToCompare == null) {
      setAllValues({ ...allValues, newTextToCompare:  JSON.parse(e.target.textContent) });
      //handleShow()
    }

    if (
      allValues.oldTextToCompare !== null &&
      allValues.newTextToCompare !== null
    ) {
      handleShow();
    }

    //setTextSelected(e.target.textContent)
    // console.log(textSelectd)
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const searchParams = {
      from_date: event.target.fromDate.value,
      to_date: event.target.toDate.value,
      entity_type: event.target.entityType.value,
      action: event.target.action.value,
      user_id: event.target.userId.value,
      organization_id: event.target.organizationId.value,
      entity_id: event.target.entityId.value,
    };

    // console.log(searchParams)

    setAllValues({
      ...allValues,
      searchParams: Object.fromEntries(
        Object.entries(searchParams).filter(([_, v]) => v != null && v !== "")
      ),
    });
   // console.log(allValues);
  };

  const handleAuth = (event) => {
    event.preventDefault();
    setAllValues({ ...allValues, jwt: event.target.jwtToken.value });
  };

  return (
    <>
      <Modal size="lg" show={allValues.show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>DIFF BETWEEN ENTRIES</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="breakword">
          <ReactJsonViewCompare oldData={allValues.oldTextToCompare} newData={allValues.newTextToCompare} />
     
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Authorization</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={handleAuth}>
              <Form.Group className="mb-3" controlId="jwtToken">
                <Form.Label>AuthorizationBearerJWT</Form.Label>
                <Form.Control type="text" />
                <Form.Text className="text-muted">Add a JWT</Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit">
                Authorize
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Search Form</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                <Form.Group className="mb-3" controlId="fromDate">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    type="datetime"
                    defaultValue="2023-01-18 00:00:00"
                  />
                  <Form.Text className="text-muted">
                    example: 2022-12-27 10:25:39 *date must be in UTC
                  </Form.Text>
                </Form.Group>
                </Col>
                <Col>
                <Form.Group className="mb-3" controlId="toDate">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    type="datetime"
                    defaultValue="2023-01-19 23:59:59"
                  />
                  <Form.Text className="text-muted">
                    example: 2022-12-28 10:25:39  *date must be in UTC
                  </Form.Text>
                </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                <Form.Group className="mb-3" controlId="userId">
                  <Form.Label>User id</Form.Label>
                  <Form.Control type="text" />
                  <Form.Text className="text-muted">
                    example: aa1071f8-9a6e-417b-aa47-09717c3feb38
                  </Form.Text>
                </Form.Group>
                </Col>
                <Col>
                <Form.Group className="mb-3" controlId="organizationId">
                  <Form.Label>Organization id</Form.Label>
                  <Form.Control type="text" />
                  <Form.Text className="text-muted">
                    example: aa1071f8-9a6e-417b-aa47-09717c3feb38
                  </Form.Text>
                </Form.Group>
                </Col>
                </Row>
                <Row>
                <Form.Group className="mb-3" controlId="entityId">
                  <Form.Label>Entity id</Form.Label>
                  <Form.Control type="text" />
                  <Form.Text className="text-muted">
                    example: aa1071f8-9a6e-417b-aa47-09717c3feb38 or 12322
                  </Form.Text>
                </Form.Group>
                </Row>
                <Row>
                  <Col>
                   <Form.Group className="mb-3" controlId="entityType">
                  <Form.Label>Entity type</Form.Label>
                  <Form.Select aria-label="Entity type">
                    <option value="">Select an entity type</option>
                    <option value="Building">Building</option>
                    <option value="Floor">Floor</option>
                    <option value="Poi">Poi</option>
                    <option value="Path">Path</option>
                    <option value="Geofence">Geofence</option>
                    <option value="User">User</option>
                    <option value="Organization">Organization</option>
                    <option value="Group">Group</option>
                  </Form.Select>
                  </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="action">
                      <Form.Label>Action</Form.Label>
                      <Form.Select aria-label="Entity type" controlId="">
                        <option value="">Select an action</option>
                        <option value="CREATE">CREATE</option>
                        <option value="UPDATE">UPDATE</option>
                        <option value="DELETE">DELETE</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  </Row>
                  <Row>
                   <Button variant="primary" type="submit">
                  Submit
                </Button>
                </Row>
              </Form>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Row>
        <h1 className="header">
          {" "}
          <br></br>LOGS <br></br>
          <br></br>
        </h1>
      </Row>
      <Row>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Organization</th>
              <th>Action</th>
              <th>Entity Type</th>
              <th>Entity Id</th>
              <th>User</th>
              <th>Impersonated_by</th>
              <th>Notification Value</th>
            </tr>
          </thead>
          <tbody>
            {allValues.events.map((event) => {
              return (
                <tr>
                  <td>{event.timestamp}</td>
                  <td>{event.organization}</td>
                  <td>{event.action}</td>
                  <td>{event.entity_type}</td>
                  <td>{event.entity_id}</td>
                  <td>{event.performed_by_user}</td>
                  <td>{event.performed_by_impersonated_user}</td>
                  <td className="breakword" onClick={handleCellClick}>
                    {event.notification_value}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </>
  );
};

export default OperationLogs;
