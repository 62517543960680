import React from 'react';
import OperationLogs from './components/operationlogs.component';
import Container from 'react-bootstrap/Container';
import "./App.css";
import { Row } from 'react-bootstrap';

const App = () => {

  return (

    <Container fluid="xxl">
      <Row><h1 className="header">SITUM OPS LOG</h1></Row>
     
    <Row><OperationLogs></OperationLogs></Row>
  </Container>
  );
}

export default App;